<template>
    <div class="personAdmin">
        <div class="facilityBox">
            <el-form inline class="searchForm" :model="searchForm" ref="searchForm">
                <el-form-item label="企业名称" prop="enterpriseFullName">
                    <el-input v-model="searchForm.enterpriseFullName" placeholder="请输入企业名称">
                    </el-input>
                </el-form-item>
                <el-form-item label="通知类型" prop="notifyType">
                    <el-select v-model="searchForm.notifyType" clearable placeholder="请选择通知类型">
                        <el-option v-for="item in typeOptions" :key="item.Code" :label="item.Name"
                            :value="item.Code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="通知状态" prop="status">
                    <el-select v-model="searchForm.status" clearable placeholder="请选择通知状态">
                        <el-option v-for="item in statusOptions" :key="item.Code" :label="item.Name"
                            :value="item.Code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关键数据" prop="sourceData">
                    <el-input v-model="searchForm.sourceData" placeholder="请输入关键数据">
                    </el-input>
                </el-form-item>
                <el-form-item label-width="10px">
                    <el-button type="primary" size="medium" @click="search" icon="el-icon-search">搜索
                    </el-button>
                    <el-button type="primary" size="medium" @click="resetForm" icon="el-icon-delete">清空</el-button>
                    <el-button type="primary" :disabled="!tableData.length" size="medium" @click="toDealAll"
                        icon="el-icon-bell">全部回调</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" ref="table" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
                v-loading="loading">
                <el-table-column align="center" type="index" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="250" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="NotifyTypeName" label="通知类型" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="StatusName" label="通知状态" width="180" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.Status == 100">{{ scope.row.StatusName }}</el-tag>
                        <el-tag type="danger" v-if="scope.row.Status == 1">{{ scope.row.StatusName }}</el-tag>
                        <el-tag type="warning" v-if="scope.row.Status == 0">{{ scope.row.StatusName }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="SourceData" label="关键数据" width="250" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CreateTime" label="创建时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="NotifyTime" label="通知时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="DealTime" label="处理时间" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" label="操作" fixed="right" width="100">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="toDeal(scope.row)" icon="el-icon-bell">回调</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div style="text-align: left; margin-top: 10px">
                <el-pagination background @current-change="handleCurrentChange" :current-page.sync="pagination.page"
                    :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="选择需要回调的通知类型" :visible.sync="dealAllDialog" center width="800px">
            <el-radio-group v-model="notifytype" style="margin-left: 160px;transform: scale(1.2);">
                <el-radio :label="0">风控结果</el-radio>
                <!-- <el-radio :label="1">运单状态</el-radio> -->
                <el-radio :label="2">司机状态</el-radio>
                <el-radio :label="3">车辆状态</el-radio>
                <el-radio :label="4">开票完成</el-radio>
            </el-radio-group>
            <div style="display: flex; justify-content: center">
                <el-button type="primary" @click="handleDealAll" style="margin-top: 30px"
                    :disabled="notifytype == null">确认回调</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    NotifyList,
    NotifyDeal,
    AutoDeal
} from "@/api/common/common";
export default {
    data() {
        return {
            loading: false,
            typeOptions: [
                {
                    Code: 0,
                    Name: '风控结果'
                },
                // {
                //     Code: 1,
                //     Name: '运单状态'
                // },
                {
                    Code: 2,
                    Name: '司机状态'
                },
                {
                    Code: 3,
                    Name: '车辆状态'
                },
                {
                    Code: 4,
                    Name: '开票完成'
                },
            ],
            statusOptions: [
                {
                    Code: 0,
                    Name: '待通知'
                },
                {
                    Code: 1,
                    Name: '异常'
                },
                {
                    Code: 2,
                    Name: '已通知'
                }
            ],
            pagination: {
                //分页控件相关参数
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            searchForm: {
                enterpriseFullName: "",
                notifyType: null,
                status: null,
                sourceData: ''
            },
            tableData: [], //表格数据
            notifytype: null,
            dealAllDialog: false
        };
    },
    methods: {
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.page = e
            this.getNotifyList()
        },
        //搜索
        search() {
            this.pagination.page = 1;
            this.getNotifyList()
        },
        //清空
        resetForm() {
            this.searchForm = {
                enterpriseFullName: "",
                notifyType: null,
                status: null,
                sourceData: ''
            }
            this.pagination.page = 1;
            this.getNotifyList()
        },
        // 获取列表
        getNotifyList() {
            let params = {
                ...this.searchForm,
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page
            };
            this.loading = true
            NotifyList(params).then((res) => {
                this.tableData = res.data.DataList;
                this.pagination.total = Number(res.data.TotalCount);
                this.loading = false
            }).finally(() => {
                this.loading = false
            })
        },
        // 处理通知
        toDeal(item) {
            this.$confirm('确定要回调该条通知吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '请求中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                NotifyDeal({
                    id: item.Id
                }).then(res => {
                    this.$message.success('操作成功');
                    this.getNotifyList()
                }).finally(() => {
                    loading.close()
                })
            })
        },
        toDealAll() {
            this.dealAllDialog = true
            this.notifytype = null
        },
        handleDealAll() {
            const loading = this.$loading({
                lock: true,
                text: '请求中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            AutoDeal({
                notifytype: this.notifytype
            }).then(res => {
                this.$message.success('操作成功');
                this.getNotifyList()
                this.dealAllDialog = false
            }).finally(() => {
                loading.close()
            })
        }
    },
    created() {
        this.getNotifyList()
    },
};
</script>
<style lang="scss" scoped></style>